import '../../css/app.css';

const LandingPage = () => {

	return ( 
		<>
			<div id='hero' className='flex flex-col bg-black'>
				<img className="w-[50px]" src={require('../../assets/jetpool.svg').default} alt='jetpool' />
				<div className='text-white'>Coming Soon</div>
			</div>
		</>
	 );
}
 
export default LandingPage;